.footer{
    background: radial-gradient(at center center, rgb(254, 249, 195), rgb(253, 224, 71), rgb(234, 179, 8));
    text-align: center;
    padding-bottom: 1%;
    padding-top: 1%;
}

.footer__contact__form{
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
}

.footer__contact__form__Information{
    font-size: 2rem;
}

.footer__contact__form__name,.footer__contact__form__message{
    align-self: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 30%;
}

.footer__contact__form__message{
    height: 100px;
    word-break: break-all;
}

.footer__contact__form__button{
    background-color: blue;
    width: 30%;
    align-self: center;
    color: white;
    padding: 1rem 0rem 1rem 0rem;
    margin: 1rem 0rem 1rem 0rem;
}

.footer__contact__form__button:hover{
    cursor: pointer;
    text-decoration: underline;
    color: red;
}
/* The Modal (background) */
.modal__card {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal__card__content {
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 900px;
  height: 354px;
  display: flex;
  justify-content: space-around;
  border: none;
}
  
  .modal__card__element__front,.modal__card__element__back{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 30%;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 1rem;
    color: white;
    box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
    font-weight: 700;
  }
  
  .modal__card__element__front,
  .modal__card__element__front::before {
    background: linear-gradient(43deg, rgb(65, 88, 208) 0%, rgb(200, 80, 192) 46%, rgb(255, 204, 112) 100%);
  }
  
  .modal__card__element__back,
  .modal__card__element__back::before {
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  }
  
  .modal__card__element__back > div{
    float: right;
  }

  .appointmentCardList__element__description{
    margin-top: 5%;
    font-family: 'Fira Sans';
    word-break: break-word;
    overflow: scroll;
    color: black;
    height: 70%;
    width: 100%;
  }

  .modal__card__element__back__icons{
    margin-top: 5%;
    margin-left: 65%;
    display: flex;
  }

  .modal__card__element__back__icons >i{
    margin: 0% 20%;
  }

  .modal__card__element__back__icons >i:hover{
    cursor: pointer;
  }

  .appointmentCardList__element__header{
    margin-top: 5%;
  }
.Body{
    background: linear-gradient(to right, rgb(134, 239, 172), rgb(59, 130, 246), rgb(147, 51, 234));
    display: flex;
    justify-content: center;
    padding: 5% 0% 5% 0%;
}

.form {
    background-color: #15172b;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    width: 520px;
  }
  
  .form__title {
    color: #eee;
    font-family: sans-serif;
    font-size: 36px;
    font-weight: 600;
    margin-top: 30px;
  }
  
  .form__subtitle {
    color: #eee;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }
  
  .form__list__element {
    margin-top: 10%;
    height: 50px;
    position: relative;
    width: 100%;
  }
  
  .form__list__element__input {
    background-color: #303245;
    border-radius: 12px;
    border: 0;
    box-sizing: border-box;
    color: #eee;
    font-size: 18px;
    height: 100%;
    outline: 0;
    padding: 4px 20px 0;
    width: 100%;
  }
  
  .cut {
    background-color: #15172b;
    border-radius: 10px;
    height: 20px;
    left: 20px;
    position: absolute;
    top: -20px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 76px;
  }
  
  .cut-short {
    width: 50px;
  }
  
  .form__list__element__label {
    color: #65657b;
    font-family: sans-serif;
    left: 20px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 20px;
  }
  
  .form__list__element__input:focus ~ .cut {
    transform: translateY(8px);
  }
  
  .form__list__element__input:focus ~ .form__list__element__label {
    transform: translateY(-30px) translateX(10px) scale(0.75);
  }
  
  .form__list__element__input:not(:focus) ~ .form__list__element__label {
    color: #808097;
  }
  
  .form__list__element__input:focus ~ .form__list__element__label {
    color: #dc2f55;
  }
  
  .form__button {
    background-color: #08d;
    border-radius: 12px;
    border: 0;
    box-sizing: border-box;
    color: #eee;
    cursor: pointer;
    font-size: 18px;
    height: 50px;
    margin-top: 10%;
    text-align: center;
    width: 100%;
  }
  
  .form__button:active {
    background-color: #06b;
  }

  .bad-input{
    color: red;
    font-size: 0.8rem;
    margin-top: 0.5rem;
}
.body__appointmentCardList__element {
    background-color: transparent;
    width: 190px;
    height: 254px;
    perspective: 1000px;
    margin-bottom: 20px;
  }
  
  .body__appointmentCardList__element_innerCard {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .body__appointmentCardList__element:hover .body__appointmentCardList__element_innerCard {
    transform: rotateY(180deg);
  }
  
  .body__appointmentCardList__element__front,
  .body__appointmentCardList__element__back {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 1rem;
    color: white;
    box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
    font-weight: 700;
  }
  
  .body__appointmentCardList__element__front,
  .body__appointmentCardList__element__front::before {
    background: linear-gradient(43deg, rgb(65, 88, 208) 0%, rgb(200, 80, 192) 46%, rgb(255, 204, 112) 100%);
  }
  
  .body__appointmentCardList__element__back,
  .body__appointmentCardList__element__back::before {
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  }
  
  .body__appointmentCardList__element__back {
    transform: rotateY(180deg);
  }
  
  .body__appointmentCardList__element__front::before,
  .body__appointmentCardList__element__back::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: 110%;
    height: 110%;
    position: absolute;
    z-index: -1;
    border-radius: 1em;
    filter: blur(20px);
    animation: animate 5s linear infinite;
  }
  
  .body__appointmentCardList__element__back > div{
    float: right;
  }

  .body__appointmentCardList__element__back{
    display: block;
  }

  .appointmentCardList__element__description{
    margin-top: 20%;
    font-family: 'Fira Sans';
    word-break: break-word;
    overflow: scroll;
    color: black;
    height: 70%;
  }

  .body__appointmentCardList__element__back__icons{
    margin-top: 5%;
    margin-right: 15%;
    display: flex;
  }

  .body__appointmentCardList__element__back__icons >i{
    margin: 0% 20%;
  }

  .body__appointmentCardList__element__back__icons >i:hover{
    cursor: pointer;
  }

  @keyframes animate {
    0% {
      opacity: 0.3;
    }
  
    80% {
      opacity: 1;
    }
  
    100% {
      opacity: 0.3;
    }
  }


  .body__appointmentList{
    list-style-type: none;
    width: 100%;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-left: 30%;
}

.card {
    max-width: 300px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 0.5rem;
    background: #606c88;
    background: -webkit-linear-gradient(to right, #3f4c6b, #606c88);
    background: linear-gradient(to right top, #3f4c6b, #606c88);
    padding: 1rem;
    color: rgb(107, 114, 128);
    box-shadow: 0px 87px 78px -39px rgba(0,0,0,0.4);
  }
  
  .content {
    margin-left: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
  }
  
  .title {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
  }
  
  .desc {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: rgb(202, 200, 200);
  }
  
  .actions {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  
  .download, .notnow {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.375rem 0.5rem;
    text-align: center;
    font-size: 0.75rem;
    line-height: 1rem;
    color: rgb(255, 255, 255);
    outline: 2px solid transparent;
    border: 1px solid rgba(253, 253, 253, 0.363);
  }
  
  .download {
    background-color: #284385;
    font-weight: 600;
  }
  
  .download:hover {
    background-color: #153586;
  }
  
  .notnow {
    background-color: #606c88;
    font-weight: 500;
  }
  
  .notnow:hover {
    background-color: #3f4c6b;
  }

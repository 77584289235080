/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #401919;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  height: 30%;
}

.modal-content>i{
  float: right;
  padding: 5px 5px 5px 10px;
}

.modal-content>i:hover{
  cursor: pointer;
}

.modal-content>p{
  padding-top: 5rem;
  padding-left: 2rem;
  z-index: 1;
  font-size: 2em;
  color: bisque;
}